import React from "react";
import { FaDownload } from "react-icons/fa";

export const Section = ({ children, className, ...props }) => {
  return (
    <>
      <section className={`mb-3 ${className}`} {...props}>
        {children}
      </section>
    </>
  );
};

export const SectionHeader = ({ title, download, onDownload }) => {
  return (
    <h6 className="d-flex justify-content-between align-items-center text-uppercase text-muted bg-light border py-3 mb-3">
      <span className="flex-grow-1 text-center">{title}</span>
      {download && (
        <button onClick={onDownload} className="btn btn-link">
          <FaDownload />
        </button>
      )}
    </h6>
  );
};
