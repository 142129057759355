import { useSelector } from "react-redux";
import { ROUTES } from "../router/routes";
import { permitUser } from "../components/Roles";
import { ROLES } from "../consts/AppRoles";

export const useRoutes = () => {
  const currentUser = useSelector((state) => state.auth.currentUser);

  const SUB_MENU = [
    {
      name: "Schools",
      path: ROUTES.DASHBOARD.CLIENTS.INDEX,
      show: permitUser([ROLES.ADMIN, ROLES.SUPER], currentUser?.role),
    },
    {
      name: "Assessments",
      path: ROUTES.DASHBOARD.SURVEY.INDEX,
      show: permitUser(
        [ROLES.ADMIN, ROLES.SUPER, ROLES.CLIENT, ROLES.TEACHER],
        currentUser?.role
      ),
    },
    {
      name: "Teachers",
      path: ROUTES.DASHBOARD.USERS.TEACHER,
      show: permitUser(
        [ROLES.ADMIN, ROLES.SUPER, ROLES.CLIENT],
        currentUser?.role
      ),
    },
    {
      name: "Parents",
      path: ROUTES.DASHBOARD.USERS.PARENT,
      show: permitUser(
        [ROLES.ADMIN, ROLES.SUPER, ROLES.CLIENT],
        currentUser?.role
      ),
    },
    {
      name: "Students",
      path: ROUTES.DASHBOARD.STUDENTS.INDEX,
      show: permitUser(
        [ROLES.ADMIN, ROLES.SUPER, ROLES.CLIENT, ROLES.TEACHER],
        currentUser?.role
      ),
    },
  ];

  return {
    SUB_MENU,
  };
};
