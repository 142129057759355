import { Button } from "../../../components/buttons/Button";

export const BeginOnboarding = ({ register, errors }) => {
  return (
    <>
      <div className="text-center my-5">
        <h4 className="text-capitalize my-5 text-muted">welcome 👋</h4>
        <h4
          className="text-capitalize mb-5 display-6 fw-bold text-muted"
          data-aos="zoom-in"
          data-aos-delay="200"
        >
          Let's embark on a journey together!
        </h4>
        <h5
          className="text-capitalize fw-normal"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          Your details will help us tailor our services just for you.
        </h5>
      </div>
      <div className="d-flex">
        <Button
          className="btn btn-primary btn-lg w-50 mt-3 mx-auto"
          text={"Begin Jorney!"}
          type="submit"
        />
      </div>
    </>
  );
};
