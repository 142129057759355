import React from "react";
import { LogOut } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../router/routes";
import AppLogo from "../static/app-logo.png";
import { clearAuth } from "../store/features/authSlice";

export const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);

  const handleLogout = () => {
    dispatch(clearAuth());
    navigate(ROUTES.LOGIN);
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg bg-white shadow-sm">
        <div className="container">
          <AppNavbarLogo />
          {currentUser?.role}
          <a className="nav-link ms-3 border-start ps-3">
            <span className="fw-semibold text-capitalize">
              {currentUser?.name ||
                `${currentUser?.firstName} ${currentUser?.lastName}`}
            </span>
            <span className="fw-semibold text-capitalize ms-2">
              {`- ${currentUser?.userId || currentUser?.clientCode}`}
            </span>
          </a>
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item"></li>
            <li className="nav-item ms-3" onClick={handleLogout}>
              <span role="button" className="nav-link">
                Logout <LogOut size={16} />
              </span>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export const SimpleNavbar = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg bg-white shadow-sm fixed-top">
        <div className="container">
          <AppNavbarLogo />
        </div>
      </nav>
    </>
  );
};

export const AppNavbarLogo = () => {
  return (
    <Link to={ROUTES.LOGIN}>
      <img
        src={AppLogo}
        className="img-fluid me-2"
        style={{ height: "2.5rem" }}
      />
    </Link>
  );
};
