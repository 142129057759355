import { FormSectionHeader } from "./FormSectionHeader";
import { INDIAN_STATES } from "../../../consts/states";

export const ClientAddressInfo = ({ register, errors }) => {
  return (
    <>
      <FormSectionHeader title={"Organization  Address Details"} />

      {/* state */}
      <div className="mb-3">
        <label htmlFor="state" className="form-label text-capitalize required">
          state
        </label>
        <select
          autoFocus
          id="state"
          className={`form-select form-select-lg ${
            errors?.state && "is-invalid"
          }`}
          {...register("state", {
            required: {
              value: true,
              message: "Required Field",
            },
          })}
        >
          <option value="">Select State</option>
          {INDIAN_STATES.map((c) => (
            <option key={c.iso2} value={c.iso2}>
              {c.name}
            </option>
          ))}
        </select>
        <div className="invalid-feedback">
          {errors?.state && errors?.state.message}
        </div>
      </div>

      {/* city */}
      <div className="form-group">
        <label
          htmlFor="city"
          className="form-label text-start text-capitalize required"
        >
          city / town
        </label>
        <div className="mb-3">
          <input
            id="city"
            {...register("city", {
              required: {
                value: true,
                message: "Required Field",
              },
            })}
            className={`form-control form-control-lg ${
              errors.city ? "is-invalid" : ""
            }`}
            placeholder=""
            type="text"
          />
          <div className="invalid-feedback text-start text-capitalize">
            {errors.city && errors.city.message}
          </div>
        </div>
      </div>

      {/* zipcode */}
      <div className="form-group">
        <label
          htmlFor="postalCode"
          className="form-label text-start text-capitalize required"
        >
          Zip Code
        </label>
        <div className="mb-3">
          <input
            id="postalCode"
            {...register("postalCode", {
              required: {
                value: true,
                message: "Required Field",
              },
              pattern: {
                value: /^[1-9][0-9]{5}$/,
                message: "Please enter a valid 6-digit ZIP code",
              },
            })}
            className={`form-control form-control-lg ${
              errors.postalCode ? "is-invalid" : ""
            }`}
            placeholder=""
            type="text"
          />
          <div className="invalid-feedback text-start text-capitalize">
            {errors.postalCode && errors.postalCode.message}
          </div>
        </div>
      </div>

      {/* address */}
      <div className="form-group">
        <label
          htmlFor="address"
          className="form-label text-start text-capitalize required"
        >
          Address
        </label>
        <div className="mb-3">
          <input
            id="address"
            {...register("address", {
              required: {
                value: true,
                message: "Required Field",
              },
            })}
            className={`form-control form-control-lg ${
              errors.address ? "is-invalid" : ""
            }`}
            placeholder=""
            type="text"
          />
          <div className="invalid-feedback text-start text-capitalize">
            {errors.address && errors.address.message}
          </div>
        </div>
      </div>
    </>
  );
};
