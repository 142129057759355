import { Outlet } from "react-router-dom";
import { AuthGuard } from "../components/AuthGuard";
import { Navbar } from "../components/Navbar";
import { SubNavbar } from "../components/SubNavbar";

export const AppLayout = () => {
  return (
    <>
      <div className="sticky-top">
        <Navbar />
        <SubNavbar />
      </div>
      <div className="container py-3">
        <AuthGuard>
          <Outlet />
        </AuthGuard>
      </div>
    </>
  );
};
