export const API_URL = {
  GET_USERS: "/users",
  GET_STATS: "/stats",
  GET_ALL_CLOSETTE: "/admin/closette",
  GET_PRODUCTS: "/products",

  // Authentication
  CLIENT_LOGIN: "auth/client/login",

  // clients
  CLIENTS: "/clients",
  CLIENT_ONBOARD: "/clients/onboard",
  CLIENT_VERIFY: "/clients/onboard/verification",
  CLIENT_GENERATE_CODE: "/clients/generate/code",
  CLIENT_UPDATE_STATUS: "/clients/update/status",
  CLIENT_RESET_PASSWORD: "/clients/reset-password",
  CLIENT_VERIFY_ADMIN: "/clients/verify",
  CLIENTS_STATS: "/clients/statistics",

  // usrs
  USERS: "/users",
  USERS_LIST: "/users/admin",
  USERS_UPDATE_STATUS: "/users/update/status",
  USER_VERIFY_ADMIN: "/users/verify",
  USERS_UPDATE: "/users/update",
  USERS_DELETE: "/users/delete",
  USERS_STATS: "/users/stats",
  TEACHERS_UPLOAD: "/users/teachers/upload",
  PARENTS_UPLOAD: "/users/parents/upload",
  TEACHER_ONBOARD: "/users/teacher/onboard",
  PARENTS_ONBOARD: "/users/parent/onboard",

  // students
  STUDENTS_LIST: "/students",
  STUDENTS_UPLOAD: "/students/upload",
  STUDENTS_UPDATE_STATUS: "/students/update-status",
  STUDENTS_VERIFY: "/students/verify",
  STUDENTS_UPDATE: "/students/update",
  STUDENTS_ONBOARD: "/students/onboard",
  STUDENTS_DELETE: "/students/delete",
  SURVEYS: "/survey",
  SURVEYS_DELETE: "/survey/remove",
  SURVEYS_UPLOAD: "/survey/upload",
  SURVEYS_STD_RESPONSES: "/survey/responses",
  SURVEYS_STD_ALLRESULTS: "/survey/allresults",
  SURVEYS_STD_RESULTS: "/survey/responses/results",
  SURVEYS_UPDATE_QUESTIONS: "/survey/update/questions",
  SURVEYS_DELETE_QUESTIONS: "/survey/delete",
  SURVEYS_SCHOOL_REPORT: "/survey/school-report",
  SURVEYS_STUDENT_REPORT: "/survey/student-report",
  SURVEYS_CLASSROOM_REPORT: "/survey/classroom-report",
};
