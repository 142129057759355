export const INDIAN_STATES = [
  { name: "Andaman and Nicobar Islands", isoCode: "IN-AN", iso2: "AN" },
  { name: "Andhra Pradesh", isoCode: "IN-AP", iso2: "AP" },
  { name: "Arunachal Pradesh", isoCode: "IN-AR", iso2: "AR" },
  { name: "Assam", isoCode: "IN-AS", iso2: "AS" },
  { name: "Bihar", isoCode: "IN-BR", iso2: "BR" },
  { name: "Chandigarh", isoCode: "IN-CH", iso2: "CH" },
  { name: "Chhattisgarh", isoCode: "IN-CT", iso2: "CT" },
  { name: "Dadra and Nagar Haveli", isoCode: "IN-DN", iso2: "DN" },
  { name: "Daman and Diu", isoCode: "IN-DD", iso2: "DD" },
  { name: "Delhi", isoCode: "IN-DL", iso2: "DL" },
  { name: "Goa", isoCode: "IN-GA", iso2: "GA" },
  { name: "Gujarat", isoCode: "IN-GJ", iso2: "GJ" },
  { name: "Haryana", isoCode: "IN-HR", iso2: "HR" },
  { name: "Himachal Pradesh", isoCode: "IN-HP", iso2: "HP" },
  { name: "Jharkhand", isoCode: "IN-JH", iso2: "JH" },
  { name: "Karnataka", isoCode: "IN-KA", iso2: "KA" },
  { name: "Kerala", isoCode: "IN-KL", iso2: "KL" },
  { name: "Lakshadweep", isoCode: "IN-LD", iso2: "LD" },
  { name: "Madhya Pradesh", isoCode: "IN-MP", iso2: "MP" },
  { name: "Maharashtra", isoCode: "IN-MH", iso2: "MH" },
  { name: "Manipur", isoCode: "IN-MN", iso2: "MN" },
  { name: "Meghalaya", isoCode: "IN-ML", iso2: "ML" },
  { name: "Mizoram", isoCode: "IN-MZ", iso2: "MZ" },
  { name: "Nagaland", isoCode: "IN-NL", iso2: "NL" },
  { name: "Odisha", isoCode: "IN-OR", iso2: "OR" },
  { name: "Puducherry", isoCode: "IN-PY", iso2: "PY" },
  { name: "Punjab", isoCode: "IN-PB", iso2: "PB" },
  { name: "Rajasthan", isoCode: "IN-RJ", iso2: "RJ" },
  { name: "Sikkim", isoCode: "IN-SK", iso2: "SK" },
  { name: "Tamil Nadu", isoCode: "IN-TN", iso2: "TN" },
  { name: "Telangana", isoCode: "IN-TG", iso2: "TG" },
  { name: "Tripura", isoCode: "IN-TR", iso2: "TR" },
  { name: "Uttar Pradesh", isoCode: "IN-UP", iso2: "UP" },
  { name: "Uttarakhand", isoCode: "IN-UT", iso2: "UT" },
  { name: "West Bengal", isoCode: "IN-WB", iso2: "WB" },
];
