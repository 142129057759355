import { Outlet } from "react-router-dom";
import { SimpleNavbar } from "../components/Navbar";

export const EmptyLayout = () => {
  return (
    <>
      <SimpleNavbar />
      <Outlet />
    </>
  );
};
