import { FormSectionHeader } from "./FormSectionHeader";
import { INDIAN_STATES } from "../../../consts/states";

export const ClientAdminInfo = ({ register, errors }) => {
  return (
    <>
      <FormSectionHeader title={"Organization  Administrator Details"} />
      <div className="alert alert-light mb-4">
        We value your privacy! In case organizational details are not reachable,
        providing your contact information ensures we can stay connected and
        assist you effectively.
      </div>

      {/* adminstrator full name */}
      <div className="form-group">
        <label
          htmlFor="adminName"
          className="form-label text-start text-capitalize required"
        >
          Administrator Name
        </label>
        <div className="mb-3">
          <input
            id="adminName"
            autoFocus
            {...register("adminName", {
              required: {
                value: true,
                message: "Required Field",
              },
              pattern: {
                value: /^[a-zA-Z0-9\s]*$/,
                message: "Only alphanumeric characters are allowed",
              },
            })}
            className={`form-control form-control-lg ${
              errors.adminName ? "is-invalid" : ""
            }`}
            placeholder=""
            type="text"
          />
          <div className="invalid-feedback text-start text-capitalize">
            {errors.adminName && errors.adminName.message}
          </div>
        </div>
      </div>
      <>
        {/* email */}
        <div className="form-group">
          <label
            htmlFor="adminEmail"
            className="form-label text-start text-capitalize required"
          >
            Administrator Email
          </label>
          <div className="mb-3">
            <input
              id="adminEmail"
              {...register("adminEmail", {
                required: {
                  value: true,
                  message: "Required Field",
                },
              })}
              className={`form-control form-control-lg ${
                errors.adminEmail ? "is-invalid" : ""
              }`}
              type="email"
            />
            <div className="invalid-feedback text-start text-capitalize">
              {errors.adminEmail && errors.adminEmail.message}
            </div>
          </div>
        </div>

        {/* admin contact */}
        <div className="form-group">
          <label
            htmlFor="adminContact"
            className="form-label text-start text-capitalize required"
          >
            Administrator Contact No.
          </label>
          <div className="mb-3">
            <input
              id="adminContact"
              {...register("adminContact", {
                required: {
                  value: true,
                  message: "Required Field",
                },
                pattern: {
                  value: /^\d{10}$/,
                  message:
                    "Only numeric characters are allowed, max 10 digits allowed",
                },
              })}
              className={`form-control form-control-lg ${
                errors.adminContact ? "is-invalid" : ""
              }`}
              type="text"
            />
            <div className="invalid-feedback text-start text-capitalize">
              {errors.adminContact && errors.adminContact.message}
            </div>
          </div>
        </div>
      </>
    </>
  );
};
