import { ROLES } from "../consts/AppRoles";

import { usePermission } from "../hooks/usePermission";

// Allowing fro mulitiple users at a time
export const OnlyFor = ({ roles = [], role = "", children }) => {
  const isAllowed = usePermission(roles);
  if (isAllowed) return <>{children}</>;
};

// Allowing fro mulitiple users at a time
export const permitUser = (roles = [], role = "") => {
  return roles.includes(role);
};
