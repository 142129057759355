import { FormSectionHeader } from "./FormSectionHeader";

export const ClientGeneralInfo = ({ register, errors }) => {
  return (
    <div data-aos="fade-in">
      <FormSectionHeader title={"Organization General Details"} />

      <div className="form-group">
        <label
          htmlFor="clientName"
          className="form-label text-start text-capitalize required"
        >
          Organization Name
        </label>
        <div className="mb-3">
          <input
            id="clientName"
            autoFocus
            {...register("clientName", {
              required: {
                value: true,
                message: "Required Field",
              },
              pattern: {
                value: /^[a-zA-Z0-9\s]+$/,
                message: "Only alphanumeric characters are allowed",
              },
            })}
            className={`form-control form-control-lg ${
              errors.clientName ? "is-invalid" : ""
            }`}
            placeholder=""
            type="text"
          />
          <div className="invalid-feedback text-start text-capitalize">
            {errors.clientName && errors.clientName.message}
          </div>
        </div>
      </div>
      <>
        {/* email */}
        <div className="form-group">
          <label
            htmlFor="clientEmail"
            className="form-label text-start text-capitalize required"
          >
            Organization Email
          </label>
          <div className="mb-3">
            <input
              id="clientEmail"
              {...register("clientEmail", {
                required: {
                  value: true,
                  message: "Required Field",
                },
              })}
              className={`form-control form-control-lg ${
                errors.clientEmail ? "is-invalid" : ""
              }`}
              type="email"
            />
            <div className="invalid-feedback text-start text-capitalize">
              {errors.clientEmail && errors.clientEmail.message}
            </div>
          </div>
        </div>

        {/* contact */}
        <div className="form-group">
          <label
            htmlFor="clientContact"
            className="form-label text-start text-capitalize required"
          >
            Organization Contact No.
          </label>
          <div className="mb-3">
            <input
              id="clientContact"
              {...register("clientContact", {
                required: {
                  value: true,
                  message: "Required Field",
                },
                pattern: {
                  value: /^(\+91[\-\s]?)?[0]?(91)?[6789]\d{9}$/,
                  message:
                    "Only numeric characters are allowed, max 10 digits allowed",
                },
              })}
              className={`form-control form-control-lg ${
                errors.clientContact ? "is-invalid" : ""
              }`}
              type="text"
            />
            <div className="invalid-feedback text-start text-capitalize">
              {errors.clientContact && errors.clientContact.message}
            </div>
          </div>
        </div>
      </>
    </div>
  );
};
