import Skeleton from "react-loading-skeleton";
import { Navbar } from "../components/Navbar";
import { SubNavbar } from "../components/SubNavbar";
import { Spinner } from "../components/spinner";

const AppLayoutLoader = ({ chlidren }) => {
  return (
    <>
      <div className="sticky-top">
        <Navbar />
        <SubNavbar />
      </div>
      <div className="container py-3">
        <Spinner />
      </div>
    </>
  );
};

export default AppLayoutLoader;
