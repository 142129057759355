import { useForm } from "react-hook-form";
import "./onboard.scss";
import { Eye, EyeOff, Link, Mail, PhoneCall } from "react-feather";

import { ROUTES } from "../../../router/routes";
import { useToggle } from "../../../hooks/useToggle";
import { Button } from "../../../components/buttons/Button";
import { Navbar } from "../../../components/Navbar";
import { useState } from "react";
import { Brand } from "./Brand";
import { BeginOnboarding } from "./BeginOnboarding";
import { ClientGeneralInfo } from "./ClientGeneralInfo";
import { ClientAddressInfo } from "./ClientAddressInfo";
import { ClientAdminInfo } from "./ClientAdminInfo";
import { ClientAdditionalInfo } from "./ClientAdditionalInfo";
import { useMutation } from "@tanstack/react-query";
import { clientRegistration } from "../../../api/api-service";
import { LeftContent } from "./LeftContent";
import { useNavigate } from "react-router-dom";
import AppLogo from "../../../static/app-logo.png";

export const ClientOnboard = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const [tabs, setTabs] = useState([1, 2, 3, 4, 5]);
  const [activeTab, setActiveTab] = useState(0);

  const onBoardForm = new FormData();
  const onboardMutation = useMutation({
    mutationFn: clientRegistration,
    onSuccess: (res) => {
      console.log(res);
      if (res.success)
        navigate(ROUTES.CLIENT.ONBOARDING_SUCCESS, {
          replace: true,
        });
    },
  });

  // Render the A ctive Tab Component
  const getActiveFormComponent = () => {
    switch (activeTab) {
      case 0:
        return <BeginOnboarding register={register} errors={errors} />;
      case 1:
        return <ClientGeneralInfo register={register} errors={errors} />;
      case 2:
        return <ClientAddressInfo register={register} errors={errors} />;
      case 3:
        return <ClientAdminInfo register={register} errors={errors} />;
      case 4:
        return <ClientAdditionalInfo register={register} errors={errors} />;
      default:
        return <BeginOnboarding register={register} errors={errors} />;
    }
  };

  const prevTab = () => {
    if (activeTab - 1 > 0) setActiveTab((prev) => prev - 1);
  };

  const nextTab = () => {
    setActiveTab((prev) => prev + 1);
  };

  const handleFormSubmit = (data) => {
    if (activeTab < tabs.length - 1) {
      return nextTab();
    }

    const payload = {
      name: data.clientName,
      location: {
        state: data.state,
        city: data.city,
        postalCode: data.postalCode,
        address: data.address,
      },
      phone: data.clientContact,
      email: data.clientEmail,
      administrator: {
        name: data.adminName,
        email: data.adminEmail,
        contact: data.adminContact,
      },
      website: data.website,
      about: data.about,
      termsAndConditions: true,
    };

    onboardMutation.mutate({
      payload,
    });
  };

  return (
    <>
      {/* <Navbar /> */}
      <div className="row bg-white m-0 client">
        <div className="col-sm-12 col-md-6 col-lg-6 offset-md-3 offset-lg-3">
          <div className="p-3 mt-4 mb-5">
            <form
              className="client-form"
              onSubmit={handleSubmit(handleFormSubmit)}
            >
              <div className="text-center mb-3">
                <img
                  src={AppLogo}
                  className="img-fluid"
                  style={{ height: "8rem", marginTop: "5%" }}
                  data-aos="zoom-in"
                />
              </div>
              {/* warnning message */}
              {activeTab > 0 && (
                <div className="alert alert-warning">
                  ⚠️ Note: Please avoid refreshing the page while entering your
                  details to ensure a smooth onboarding process
                </div>
              )}

              <div>{getActiveFormComponent()}</div>

              {/* Action Buttons */}
              {activeTab > 0 ? (
                <div className="d-flex justify-content-between mb-3 bg-white py-2">
                  <Button
                    type="button"
                    className="btn btn-light px-4"
                    text="< Prev"
                    onClick={() => prevTab()}
                    disabled={activeTab <= 0 || onboardMutation.isPending}
                  />
                  <Button
                    loading={onboardMutation.isPending}
                    disabled={onboardMutation.isPending}
                    className="btn btn-primary px-4"
                    text={activeTab === tabs.length - 1 ? "Submit" : "Next >"}
                    type="submit"
                  />
                </div>
              ) : null}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
