const APP_BASE = "/";
const DASHBOARD_BASE = "/me";

export const ROUTES = {
  APP_BASE: APP_BASE,
  LOGIN: "/auth/login",
  RESET_PASSWORD: "/auth/reset-password",
  NO_MATCH_ROUTE: "*",

  // Dashboard Routes
  DASHBOARD_BASE: DASHBOARD_BASE,
  PROFILE: DASHBOARD_BASE + "/profile",

  // Clients
  CLIENT: {
    INDEX: "/client/onboarding",
    ONBOARD: "/client/onboarding/request",
    ONBOARDING_VERIFICATION: "/client/onboarding/verification",
    ONBOARDING_SUCCESS: "/client/onboarding/success",
  },

  DASHBOARD: {
    INDEX: DASHBOARD_BASE,

    CLIENTS: {
      INDEX: DASHBOARD_BASE + "/clients",
      REQUESTS: DASHBOARD_BASE + "/clients/requests",
    },

    USERS: {
      TEACHER: DASHBOARD_BASE + "/users/teachers",
      PARENT: DASHBOARD_BASE + "/users/parents",
    },

    RESULTS: {
      INDEX: DASHBOARD_BASE + "/results",
    },

    // students
    STUDENTS: {
      INDEX: DASHBOARD_BASE + "/students",
    },

    SURVEY: {
      INDEX: DASHBOARD_BASE + "/assessments",
    },
  },
};
