import { Eye, EyeOff } from "react-feather";
import { useForm } from "react-hook-form";
import "./login.scss";

import { Section } from "../../components/Section";
import { Button } from "../../components/buttons/Button";
import { useToggle } from "../../hooks/useToggle";
import { ROUTES } from "../../router/routes";

import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  ClientLogin,
  fetchClientById,
  fetchUserById,
} from "../../api/api-service";
import { ROLES } from "../../consts/AppRoles";
import AppLogoVert from "../../static/app-logo.png";
import {
  setAuthToken,
  setCurrentRole,
  setCurrentUser,
  setCurrentUserId,
} from "../../store/features/authSlice";

const Route = {
  SUPER: ROUTES.DASHBOARD.CLIENTS.INDEX, // super admin
  ADMIN: ROUTES.DASHBOARD.CLIENTS.INDEX, // admin
  CLIENT: ROUTES.DASHBOARD.SURVEY.INDEX, // school
  TEACHER: ROUTES.DASHBOARD.SURVEY.INDEX, // teacher
};

export const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.currentUser);

  useEffect(() => {
    if (currentUser?.role)
      return navigate(Route[currentUser.role], {
        replace: true,
      });
  }, []);

  const [user, setUser] = useState({
    id: null,
    role: null,
  });

  const { isToggle, handleToggle } = useToggle();

  const clientQuery = useQuery({
    queryKey: ["client", user.id],
    queryFn: () => fetchClientById({ id: user.id }),
    enabled: !!(user?.id && user?.role === ROLES.CLIENT),
  });

  const userQuery = useQuery({
    queryKey: ["user", user.id],
    queryFn: () => fetchUserById({ id: user.id }),
    enabled: !!(
      user?.id &&
      (user?.role === ROLES.SUPER ||
        user?.role === ROLES.ADMIN ||
        user?.role === ROLES.TEACHER)
    ),
  });

  if (clientQuery.isSuccess || userQuery.isSuccess) {
    let content = clientQuery.data?.content || userQuery.data?.content;
    dispatch(setCurrentUser(content));

    // based on role redirec to dashboard
    if (!Route[content.role]) navigate(ROUTES.LOGIN);
    navigate(Route[content.role], {
      replace: true,
    });
  }

  const { mutate, isPending } = useMutation({
    mutationFn: ClientLogin,
    onSuccess: (res) => {
      if (res.success) {
        if (res.content.role === ROLES.PARENT)
          return toast("Bad Request", {
            type: "error",
          });
        dispatch(setAuthToken(res.content.token));
        dispatch(setCurrentUserId(res.content.id));
        dispatch(setCurrentRole(res.content?.role));
        setUser({
          id: res.content.id,
          role: res.content.role,
        });
      }
    },
    onError: () => {},
  });

  const handleFormSubmit = (data) => {
    mutate({
      clientCode: data.username,
      password: data.password,
    });
  };

  return (
    <>
      <div className="text-center">
        <img
          src={AppLogoVert}
          className="img-fluid"
          style={{ height: "8rem", marginTop: "5%" }}
        />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-4 offset-lg-4">
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <div className="text-center mb-4">
                <h4 className="text-muted p-3">Login</h4>
              </div>

              <fieldset disabled={isPending}>
                <Section>
                  <div>
                    <label
                      htmlFor="input-username"
                      className="form-label text-start required"
                    >
                      Email / UserCode
                    </label>
                    <div className="mb-3">
                      <input
                        id="input-username"
                        {...register("username", {
                          required: {
                            value: true,
                            message: "Please Enter School Code or Email",
                          },
                        })}
                        className={`form-control form-control-lg ${
                          errors.username ? "is-invalid" : ""
                        }`}
                        placeholder="NGF466"
                        type="text"
                      />
                      <div className="invalid-feedback text-start">
                        {errors.username && errors.username.message}
                      </div>
                    </div>
                    <label
                      htmlFor="input-paassword"
                      className="form-label text-start required"
                    >
                      Password
                    </label>
                    <div className="input-group mb-3">
                      <input
                        id="input-paassword"
                        {...register("password", {
                          required: {
                            value: true,
                            message: "Please Enter Password",
                          },
                        })}
                        className={`form-control form-control-lg ${
                          errors.password ? "is-invalid" : ""
                        }`}
                        placeholder="Password"
                        type={isToggle ? "text" : "password"}
                      />
                      <button
                        type="button"
                        onClick={handleToggle}
                        style={{ cursor: "pointer" }}
                        className="input-group-text rounded-end"
                      >
                        {isToggle ? (
                          <Eye size={16} color="grey" />
                        ) : (
                          <EyeOff size={16} color="grey" />
                        )}
                      </button>
                      <div className="invalid-feedback text-start">
                        {errors.password && errors.password.message}
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <Link
                        className="btn btn-link mb-3 w-100"
                        type="button"
                        to={ROUTES.RESET_PASSWORD}
                      >
                        Forgot Password ?
                      </Link>
                      <Button
                        text={"Sign In"}
                        className="btn btn-lg btn-primary  w-100"
                        type="submit"
                        loading={isPending}
                      />
                    </div>
                  </div>
                </Section>
                <Section>
                  <p className="text-center mt-5">Join As Partner ?</p>
                  <Link
                    to={ROUTES.CLIENT.ONBOARD}
                    className="btn btn-link w-100 mb-3"
                  >
                    Partner Registration
                  </Link>
                </Section>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
