import { FormSectionHeader } from "./FormSectionHeader";
import { INDIAN_STATES } from "../../../consts/states";

export const ClientAdditionalInfo = ({ register, errors }) => {
  return (
    <>
      <FormSectionHeader title={"Organization  Additional Details"} />
      <div className="alert alert-light mb-5">
        Enhance your profile by providing optional details. Your additional
        information helps us personalize your experience but is not mandatory.
      </div>

      {/* website */}
      <div className="form-group">
        <label
          htmlFor="website"
          className="form-label text-start text-capitalize"
        >
          Organization Website URL
        </label>
        <div className="mb-3">
          <input
            id="website"
            autoFocus
            {...register("website", {})}
            className={`form-control form-control-lg ${
              errors.website ? "is-invalid" : ""
            }`}
            placeholder=""
            type="text"
          />
          <div className="invalid-feedback text-start text-capitalize">
            {errors.website && errors.website.message}
          </div>
        </div>
      </div>

      <div className="form-group mb-3">
        <label htmlFor="about" className="form-label">
          About Organization
        </label>
        <textarea
          maxLength={255}
          rows={5}
          className={`form-control ${errors.about ? "is-invalid" : ""}`}
          {...register("about", {})}
        ></textarea>
        <div className="invalid-feedback text-start">
          {errors.about && errors.about.message}
        </div>
      </div>
    </>
  );
};
