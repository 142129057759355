import React, { useEffect } from "react";
import { Brand } from "./Brand";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../../router/routes";
import { Spinner } from "../../../components/spinner";
import { useQuery } from "@tanstack/react-query";
import { clientAccVerification } from "../../../api/api-service";
import { Button } from "../../../components/buttons/Button";
import { toast } from "react-toastify";

export const ClientEmailVerification = () => {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const token = queryParams.get("token");

  const query = useQuery({
    queryKey: ["clients/verify"],
    queryFn: () => clientAccVerification({ token }),
    enabled: !!token,
  });

  useEffect(() => {
    if (!token) navigate(ROUTES.LOGIN);
  }, []);

  if (query.isLoading)
    return (
      <Spinner
        text={
          "Your account verification is underway, ensuring a seamless journey ahead."
        }
      />
    );

  if (query.isError) return <Navigate to={ROUTES.LOGIN} replace />;

  return (
    <>
      <div className="row bg-white m-0 client">
        <div className="col-sm-12 col-md-8 col-lg-6 p-0 d-none d-md-block d-lg-block">
          <div className="onboard-banner d-flex align-items-end p-5">
            <div>
              <h1 className="text-white display-6 fw-bold text-capitalize">
                Unlock! the world of possibilities
              </h1>
              <h5 className="text-white fw-normal">
                Connect with us and let the adventure begin!
              </h5>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-8 col-lg-6 p-0">
          <div className="p-5 mt-5">
            <h6 className="text-center">Account Verification!</h6>
            <div className="text-center my-5 p-5">
              <>
                <h4 className="text-capitalize mb-5 text-muted">
                  Hurray! Account Verified 🎉
                </h4>

                <h4
                  className="text-capitalize mb-5 display-6 fw-bold text-muted"
                  data-aos="zoom-in"
                  data-aos-delay="200"
                >
                  Welcome aboard!
                </h4>
                <h5
                  className="text-muted fw-normal"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  Thrilled to have you as our valued client. Get ready for a
                  journey of seamless experiences tailored just for you!
                </h5>
              </>
            </div>

            <div className="d-flex justify-content-center">
              <Button
                className="btn btn-primary btn-lg w-50 mt-3"
                text={"Sign In"}
                onClick={() =>
                  navigate(ROUTES.LOGIN, {
                    replace: true,
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
